import React from 'react';
import './footer.scss';

function Footer() {
  return (
    <footer>
      © 2021 FINE TUNE MARINE LLC | All rights reserved |
    </footer>
  );
}

export default Footer;